import React, {useEffect, useState} from 'react';
import CallToAction from '../CallToAction/CallToAction';

const NextAvailableLesson = () => {
    return (
        <div className="px-4 text-center mt-4 mb:mt-8">
            {/* <p>
                Don't miss out to enrol, our next available lesson is <strong>27/03/23, at 08:30AM</strong>!
            </p> */}

            {/* <p className="text-xs mb-3 md:mb-6">
                Last updated: <em>19/11/22, at 07:50PM</em>
            </p> */}

            <CallToAction type="standard" href="tel:+61432123483" content="Book Now!" />
        </div>
    );
};

export default NextAvailableLesson;
