import React from 'react';

const Logo = () => {
    return (
        <div className="text-center mix-blend-multiply z-50 select-none w-full max-w-lg mx-auto pr-4">
            <img src="/assets/logo.png" alt="Woodward Driving Academy" />
            {/* <p>
                <span className="text-copper-red font-cormorant text-4xl md:text-5xl lg:text-6xl font-black">Woodward</span>
                <br />
                <span className="text-gold-metallic font-roboto text-2xl md:text-3xl lg:text-4xl font-bold">Driving Academy</span>
            </p> */}
        </div>
    );
};

export default Logo;
