import React from 'react';

const Subheading = (props) => {
    switch (props.type) {
        case 'h2':
            return <h2 className="font-cormorant text-3xl md:text-4xl lg:text-5xl text-raisin-black font-bold text-center my-3">{props.content}</h2>;
        case 'h3':
            return <h3 className="text-xl md:text-2xl lg:text-3xl text-raisin-black font-bold text-center my-3">{props.content}</h3>;
        default:
            return <h3 className="text-xl md:text-2xl lg:text-3xl text-raisin-black font-bold text-center my-3">{props.content}</h3>;
    }
};

export default Subheading;
