import React from 'react';
import CallToAction from '../CallToAction/CallToAction';

const PricingStructure = () => {
    const plans = [
        {
            name: 'Standard Lesson',
            tag: 'Most Popular!',
            cost: 75,
            points: [
                'a comprehensive, tailored plan for each student',
                'unique and thorough driving experiences',
                'a tight feedback loop between instructor and driver',
                'express last-minute-lessons options available',
            ],
            ctaContent: 'Start Learning!',
        },
        {
            name: 'Extended Lesson',
            tag: '',
            cost: 139,
            points: [
                'a Standard Lesson over an extended period',
                'excellent for promising students who enjoy learning',
                'a comfortable pace to maximise flow-state experience',
            ],
            ctaContent: 'Book Now',
        },
        {
            name: 'Pre-Flight Lesson',
            tag: 'Very Popular!',
            cost: 175,
            points: [
                'a custom, pre-flight lesson the day of a test',
                'student-orientated to reduce anxiety and fill last-minute knowledge-gaps',
                'vehicle loan during the test (or use your own car!)',
                'vehicle pre-inspection to avoid uneccessary stress and failures',
            ],
            ctaContent: 'Book Now',
        },
        {
            name: 'Top-Up Lesson',
            tag: '',
            cost: 89,
            points: [
                'a unique, casual lesson to top-up driving skills',
                "perfect for adult-age students who haven't driven recently",
                'aimed at filling holes in skillsets, reducing fears and removing uncertainties',
            ],
            ctaContent: 'Improve Your Abilities',
        },
    ];

    const createPricingStructureItem = () => {
        const items = [];

        plans.forEach((plan, planIndex) => {
            items.push(
                <li className="flex flex-col rounded-md shadow-sm p-4 pt-6 border relative my-4 max-w-xs mx-2 justify-between" key={planIndex}>
                    <div>
                        {plan.tag.length > 0 && (
                            <span className="block absolute -top-4 bg-gold-metallic text-white p-2 rounded-md shadow-lg text-xs scale-up-center">
                                {plan.tag}
                            </span>
                        )}
                        <h3 className="text-center text-2xl">{plan.name}</h3>
                        <div className="block my-4">
                            <div className="text-center text-5xl font-bold flex items-start justify-center">
                                <span className="text-sm font-normal pt-2">$</span>
                                {plan.cost}
                                <span className="text-sm font-normal pt-2">AUD</span>
                            </div>
                        </div>
                        <div>
                            <ul>
                                {plan.points.map((listItem, listItemIndex) => {
                                    return (
                                        <li className="text-center text-sm p-2 border-b-2" key={listItemIndex}>
                                            {listItem}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="flex justify-center mt-4">
                        <CallToAction href="tel:+61432123483" type="standard" content={plan.ctaContent} />
                    </div>
                </li>,
            );
        });
        return items;
    };

    return (
        <div>
            <h3 className="mt-16 text-xl md:text-2xl lg:text-3xl text-raisin-black font-cormorant font-bold mt-3 p-2 bg-white inline-block">
                Prices and Plans
            </h3>
            <hr className="-mt-6 mb-6" />
            <ul className="flex flex-col items-center md:justify-center md:items-stretch md:flex-row flex-wrap md:max-w-2xl 2xl:max-w-max mx-auto">
                {createPricingStructureItem()}
            </ul>
        </div>
    );
};

export default PricingStructure;
