import React from 'react';
import CallToAction from '../CallToAction/CallToAction';

const Alert = () => {
    return (
        <div className="block w-full mx-auto top-0 bg-copper-red text-white p-0 m-0 shadow-lg">
            <div className="max-w-4xl flex p-4 m-0 mx-auto items-center justify-between flex-col md:flex-row">
                <div className="py-1 md:py-0 px-3 mt-1">Introducing our 2023 Seasonal Package! 🌞</div>
                <div className="py-1 md:py-0 px-3 text-center">
                    5 Standard Lessons, <strong>$25 Off</strong> - $350! <br />
                    10 Standard Lessons, <strong>1 Free Lesson</strong> - $675!
                </div>
                <div>
                    <CallToAction content="Details" href="tel:+61432123483" type="standard-alt" />
                </div>
            </div>
        </div>
    );
};

export default Alert;
