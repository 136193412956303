import React from 'react';

const Gallery = () => {
    const personalities = [
        {
            social: 'Brodie',
            emoji: '😊',
            image: '/assets/brodie.jpg',
        },
        {
            social: 'Georgina',
            emoji: '✨',
            image: '/assets/georgina.jpg',
        },
        {
            social: 'Jack',
            emoji: '👍',
            image: '/assets/jack.jpg',
        },
        {
            social: 'Liam',
            emoji: '🅿',
            image: '/assets/liam.jpg',
        },
    ];

    const createPersonalityItem = () => {
        const items = [];

        personalities.forEach((personality, personalityIndex) => {
            items.push(
                <li className="block flex-initial basis-1/2 sm:basis-1/4 p-2 transition-all hover:scale-105">
                    <div className="rounded-md overflow-hidden shadow-sm relative">
                        <img className="w-full h-auto" src={personality.image} width={300} height={300} alt="test" />
                        <a
                            // href={'https://instagram/' + personality.social}
                            href="#"
                            className="absolute top-4 md:top-auto md:bottom-4 left-4 text-white font-medium text-xs lg:text-lg">
                            {personality.social}
                        </a>
                        <span className="absolute bottom-4 right-4">{personality.emoji}</span>
                    </div>
                </li>,
            );
        });
        return items;
    };

    return (
        <div className="block">
            <h3 className="mt-16 text-xl md:text-2xl lg:text-3xl text-raisin-black font-cormorant font-bold mt-3 p-2 bg-white inline-block">
                Successful Students
            </h3>
            <hr className="-mt-6 mb-6" />
            <ul className="flex mx-auto justify-center align-center flex-wrap sm:flex-nowrap mb-20">{createPersonalityItem()}</ul>
        </div>
    );
};

export default Gallery;
