import React from 'react';

const CallToAction = (props) => {
    switch (props.type) {
        case 'standard':
            return (
                <a
                    className="
                        bg-copper-red
                        block
                        border-2
                        border-transparent
                        font-medium
                        my-2
                        p-2
                        rounded-md
                        shadow-sm
                        text-center
                        text-sm
                        text-white
                        transition-all
                        md:inline-block
                        hover:bg-transparent
                        hover:border-copper-red
                        hover:text-copper-red
                        active:bg-transparent
                        active:border-copper-red
                        active:text-copper-red
                        focus:bg-transparent
                        focus:border-copper-red
                        focus:text-copper-red
                    "
                    href={props.href}>
                    {props.content}
                </a>
            );
        case 'standard-alt':
            return (
                <a
                    className="
                        bg-cafe-noir
                        block
                        border-2
                        border-transparent
                        font-medium
                        my-2
                        p-2
                        rounded-md
                        shadow-sm
                        text-center
                        text-sm
                        text-white
                        transition-all
                        md:inline-block
                        hover:bg-transparent
                        hover:border-cafe-noir
                        hover:text-cafe-noir
                        active:bg-transparent
                        active:border-cafe-noir
                        active:text-cafe-noir
                        focus:bg-transparent
                        focus:border-cafe-noir
                        focus:text-cafe-noir
                    "
                    href={props.href}>
                    {props.content}
                </a>
            );
        case 'ghost':
            return (
                <a
                    className="
                    bg-transparent
                    block
                    border-2
                    border-copper-red
                    font-medium
                    my-2
                    p-2
                    rounded-md
                    shadow-sm
                    text-center
                    text-copper-red
                    text-sm
                    transition-all
                    md:inline-block
                    hover:bg-copper-red
                    hover:border-transparent
                    hover:text-white
                    active:bg-copper-red
                    active:border-transparent
                    active:text-white
                    focus:bg-copper-red
                    focus:border-transparent
                    focus:text-white
                    "
                    href={props.href}>
                    {props.content}
                </a>
            );
        case 'ghost-alt':
            return (
                <a
                    className="
                    bg-transparent
                    block
                    border-2
                    border-cafe-noir
                    font-medium
                    my-2
                    p-2
                    rounded-md
                    shadow-sm
                    text-center
                    text-cafe-noir
                    text-sm
                    transition-all
                    md:inline-block
                    hover:bg-cafe-noir
                    hover:border-transparent
                    hover:text-white
                    active:bg-cafe-noir
                    active:border-transparent
                    active:text-white
                    focus:bg-cafe-noir
                    focus:border-transparent
                    focus:text-white
                    "
                    href={props.href}>
                    {props.content}
                </a>
            );
        default:
            return <a href={props.href}>{props.content}</a>;
    }
};

export default CallToAction;
