import React from 'react';

import CallToAction from '../Components/CallToAction/CallToAction';
import Gallery from '../Components/Gallery/Gallery';
import LatestNews from '../Components/LatestNews/LatestNews';
import Logo from '../Components/Logo/Logo';
import NextAvailableLesson from '../Components/NextAvailableLesson/NextAvailableLesson';
import PricingStructure from '../Components/PricingStructure/PricingStructure';
import Subheading from '../Components/Subheading/Subheading';

const Homepage = () => {
    return (
        <div className="container mx-auto px-4 font-roboto">
            <header className="flex p-6 pt-8 flex-col">
                <Logo />
            </header>
            <main className="w-full relative m-0 p-0">
                <Subheading type="h2" content="Perth's premium driving academy" />
                <Subheading type="h3" content="Teaching modern skills for the next generation of safe drivers" />
                <NextAvailableLesson />
                <Gallery />
                <PricingStructure />
                {/* <CallToAction type="standard" href="#" content="Book an Appointment" /> */}
                {/* <CallToAction type="ghost" href="#" content="Book an Appointment" /> */}
                {/* <CallToAction type="standard-alt" href="#" content="Book an Appointment" /> */}
                {/* <CallToAction type="ghost-alt" href="#" content="Book an Appointment" /> */}
                {/*  */}
                {/* <LatestNews /> */}
            </main>
        </div>
    );
};

export default Homepage;
